.qty-input:focus {
  outline: none;
}

.list-group-item:hover {
  cursor: pointer;
  background: #f5f7fa;
}

.label-simplebar {
  max-height: calc(100% - 48px - 63px);
}

.no-results-message {
  min-height: calc(100% - 48px - 63px);
  height: calc(100% - 48px - 63px);
}

.react-pdf__Page {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root {
  --toastify-color-info: #39afd1 !important;
  --toastify-color-success: #0acf97 !important;
  --toastify-color-warning: #ffbc00 !important;
  --toastify-color-error: #fa5c7c !important;
}

.main-header {
  background: rgb(95, 154, 193);
  background: linear-gradient(
    66deg,
    rgba(95, 154, 193, 1) 33%,
    rgba(206, 224, 236, 1) 100%
  );
  /* 
    #5f9ac1
    #cee0ec
  */
}

.printed-label{
  transform: scale(1);
  border: 1px solid black;
}

.pagination{
  margin-bottom: 0;
}
